/* Ensure that the first div has a 100% height for the ARC container to work properly */
body,
html,
#root {
    height: 100%;
}

arc-navbar {
    --logo-height: 1.5rem;
}

arc-sidebar {
    --gap-distance: 1rem;
    --arc-sidebar-width: 35%;
    --sidebar-width: clamp(20rem, 40%, var(--arc-sidebar-width));
}

#content {
    height: 100% !important;
}

arc-sidebar #title>span {
    font-size: 2rem !important;
}